import http from "../common/http-common";
import IRespuesta from "../../Interfaces/IRespuesta";
import IAvisosFileManager from "../../Interfaces/Utils/IAvisosFileManager";
import { IAvisoArriboCatDocumentos } from "../../Interfaces/Catalogos/IAvisoArriboCatDocumentos";

class AvisosFileManagerService {
  AppendFile(formData: FormData, IDUser: number, IDProcess: number, IdFile: number){
    return http.post(`/AvisosFileManager/AppendFileByProcess?IdUsuario=${IDUser}&Proceso=${IDProcess}&IdAvisoArribo=${IdFile}&crud=1`, formData, 
      {
        headers:{
          "Content-Type":'multipart/form-data'
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      }
    )
  }

  getFileContent(id: number, Proceso: number) {
    return http.get(`/AvisosFileManager/getFile?id=${id}&proceso=${Proceso}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  } 
  getFileInfoFromProcess(id: number, proc: number) {
    return http.get<IAvisosFileManager>(`/AvisosFileManager/GetFileInfoByProcess?id=${id}&Proceso=${proc}`);
  }
  getFileInfoById(id: number) {
    return http.get<IAvisosFileManager>(`/AvisosFileManager/GetFileInfoById?id=${id}`);
  }
  DeleteFile(id: number) {
    return http.delete<IRespuesta>(`/AvisosFileManager/DeleteById/${id}`);
  }

  getFileContentById(id: number) {
    return http.get<ArrayBuffer>(`/AvisosFileManager/GetFileById?fileId=${id}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  }
  GetAvisoFiles(id:number) {
    return http.get<IAvisoArriboCatDocumentos[]>(`/AvisosFileManager/GetAvisoFiles/${id}`)
  }
  MarkAsRejected(document:IAvisosFileManager){
    return http.post<IAvisosFileManager>(`/AvisosFileManager/MarkAsRejected`, document)
  }

  AppendFileList(formData: any, IdAvisoArribo: number, Proceso: number, Usuario: number) {
    return http.post<IAvisosFileManager[]>(`/AvisosFileManager/AppendFileList?IdAvisoArribo=${IdAvisoArribo}&Proceso=${Proceso}&Usuario=${Usuario}`, formData,{
      headers:{
        "Content-Type":'multipart/form-data'
      }
    })
  }

  getAvisoFilesByProcess(idAviso: number, proceso: number){
    return http.get<IAvisosFileManager[]>(`/AvisosFileManager/AvisoFilesByProcess?IdAviso=${idAviso}&Proceso=${proceso}`)
  }
}
export default new AvisosFileManagerService();