import React, { useEffect, useRef, useState } from 'react'
import IAvisosFileManager from '../../../Interfaces/Utils/IAvisosFileManager'
import AvisosFileManagerServices from '../../../Services/Utils/AvisosFileManager.Services'
import { Col, Container, ListGroup, Row } from 'react-bootstrap'
import { BsFillXCircleFill } from 'react-icons/bs'

interface IProps {
    idAviso: number,
    proceso: number,
    UserId: number,
    leyenda: string,
    accept: string,
    canDelete: boolean
}
export const AvisosMFileManager = ({idAviso, proceso, UserId, leyenda, accept, canDelete}: IProps) => {
    const [ListaArchivos, setListaArchivos] = useState<IAvisosFileManager[]>([])
    const [LinkColor, setLinkColor] = useState<string>('#0d6efd');
    const fileInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        AvisosFileManagerServices.getAvisoFilesByProcess(idAviso, proceso)
        .then((response) => {setListaArchivos(response.data)})
        .catch((e: Error) => {
            alert('Ocurrio un error: ' + e)
            return
        })
    }, [])

    const selectedFiles = (selectorFiles: any) => {
        var formData = new FormData()
        for (let i = 0; i < selectorFiles.files.length; i++) {
          formData.append('FileList', selectorFiles.files[i])
        }
        AvisosFileManagerServices.AppendFileList(formData, idAviso, proceso, UserId)
          .then((response) => {
            setListaArchivos([...ListaArchivos, ...response.data])
          })
          .catch((e: Error) => {
            alert('Ocurrio un error: ' + e)
            return
          })
        return false
      }

      const deleteFile = (id: number) => {
        AvisosFileManagerServices.DeleteFile(id)
        .then((resp) => {
            setListaArchivos(prev => prev.filter(a => a.id !== id))
        })
        .catch((e: Error) => {
            alert('Ocurrio un error: ' + e)
            return
        })
      }

      const getFile = (file: IAvisosFileManager) => {
        AvisosFileManagerServices.getFileContentById(file.id)
        .then((resp: any) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', file.nombreArchivo)
            document.body.appendChild(link)
            link.click()
        })
        .catch((e: Error) => {
            alert('Ocurrio un error: ' + e)
            return
        })
      }

    return (
        <Container style={{width: '100%', padding: '15px 15px', borderRadius:'15px', border: '1px dashed #4b5dff'}}>
            <div
                className='dropzoneFileManager'
                style={{width: '100%',backgroundColor:'#D2F9FC', cursor: canDelete ? 'pointer' : 'auto'}}
                onClick={(e) => !canDelete ? e.stopPropagation() : fileInputRef.current!.click()}
            >
                <input ref={fileInputRef} type='file' multiple accept={accept} name='FileList' onChange={(e) => selectedFiles(e.target)} hidden/>
                <p>{leyenda}</p>
            </div>
            <hr/>
            <ListGroup>
                {
                    ListaArchivos ? ListaArchivos.map(a => (
                        <ListGroup.Item>
                            <Row>
                                <Col xs={11}>
                                <span style={{textDecoration:'underline', color:LinkColor, cursor:'pointer'}} 
                                    onClick={() => {
                                        getFile(a)
                                    }}
                                    onMouseEnter={() => {setLinkColor('blue')}}
                                    onMouseLeave={() => {setLinkColor('#0d6efd')}}
                                    >
                                    {a.nombreArchivo}
                                </span>
                                </Col>
                                {/* <Col xs={1}><FaDownload color='blue' style={{cursor:'pointer'}} onClick={() => {getFile(a)}}/></Col> */}
                                <Col xs={1}>{ canDelete ? <BsFillXCircleFill color='red' style={{cursor:'pointer'}} onClick={() => {deleteFile(a.id)}}/> : ""}</Col>
                            </Row>
                        </ListGroup.Item>
                    )) : ''
                }
            </ListGroup>
        </Container>
    )
}
